import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

// import { EmailInput } from '../../Home/EmailInput';
import { WaveUpHero } from '../WaveSVGs/WaveUpHero';
import { HeroBody } from '../CustomStyles/Typography';
import { ImgRight } from './ImgRight';
import { HeroCtaButton } from './HeroCtaButton';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.white,
		margin: '0',
		[theme.breakpoints.down('md')]: {
			margin: '0px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			width: '100%',
		},
	},
	header: {
		lineHeight: 1.1,
		fontSize: '3.15rem',
		margin: '0 0 1rem 0',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.4rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
			// marginTop: '20px',
		},
	},
	subHeader: {
		fontSize: '1.5rem',
		fontWeight: 400,
		lineHeight: 1.3,
		textTransform: 'uppercase',
		fontFamily: ['Roboto', 'sans-serif'].join(),
		margin: '0 0 1rem 0',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.3rem',
		},
	},
	imgCont: {
		padding: '6rem 0',
		maxWidth: '100vw',
		height: '100%',
		overflow: 'visible',
		backgroundSize: 'cover',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
		[theme.breakpoints.down('md')]: {},
		[theme.breakpoints.down('sm')]: {
			paddingTop: '3rem',
			backgroundPosition: 'right',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '3rem 0',
		},
	},
	imgContWhyServman: {
		padding: '6rem 0',
		maxWidth: '100vw',
		height: '100%',
		overflow: 'visible',
		backgroundSize: 'cover',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
		[theme.breakpoints.down('md')]: {
			padding: '6rem 0',
		},
		[theme.breakpoints.down('sm')]: {
			paddingTop: '3rem',
			backgroundPosition: 'right',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '3rem 0',
		},
	},
  headerWhyServman: {
    lineHeight: 1.1,
		fontSize: '3.15rem',
		margin: '4rem 0 1rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.4rem',
      margin: '0 0 1rem 0',
      textAlign: 'center',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
		},
  },
}));

export const HeroImageRight = ({ _id, hero, heroImage, feature }) => {
	const classes = useStyles();
	const md = useMediaQuery('(max-width: 960px)');
	const xs = useMediaQuery('(max-width: 481px)');

	const theme = useTheme();

	//if hero has subheader then remove margin-top from h1
	const isFeature =
		feature && hero._rawContent[0].style === 'h4' ? true : false;

	return (
		<>
			<BgImage
				className={
					_id === '5b22fa47-72df-4b94-a1a8-31514063b59f'
						? classes.imgContWhyServman
						: classes.imgCont
				}
				image={hero?.backgroundImage?.asset?.gatsbyImageData}
				style={{
					lineHeight: '0',
					overflowX: 'visible',
				}}>
				<Container
					fixed
					style={{ position: 'relative', maxHeight: md ? '875px' : 'none', }}>
					<Grid container direction='row' justifyContent='flex-start'>
						<Grid
							container
							item
							direction='column'
							justifyContent='flex-start'
							alignItems='flex-start'
							xs={12}
							md={heroImage ? 7 : isFeature ? 8 : 10}>
							{hero?._rawContent.map((content, index) => (
								<PortableText
									key={index}
									content={content}
									className={classes.text}
									serializers={{
										h1: ({ children }) => (
											<Typography
												variant='h1'
												className={
                          _id === '5b22fa47-72df-4b94-a1a8-31514063b59f'
                          ? classes.headerWhyServman
                          : classes.header}
												style={{ marginTop: isFeature && 0 }}>
												{children}
											</Typography>
										),
										h2: ({ children }) => (
											<Typography variant='h2' className={classes.header}>
												{children}
											</Typography>
										),
										h4: ({ children }) => (
											<Typography
												variant='h4'
												className={
													isFeature ? classes.subHeader : classes.header
												}>
												{children}
											</Typography>
										),
										normal: ({ children }) => <HeroBody whyServman={true}>{children}</HeroBody>,
									}}
								/>
							))}{' '}
							{hero.ctaButtonText && (
								// <Grid container direction='row'>
								<Grid item>
									<HeroCtaButton hero={hero} />
								</Grid>
								// </Grid>
							)}
						</Grid>

						{!!heroImage ? <ImgRight heroImage={heroImage} id={_id} /> : null}
					</Grid>
				</Container>
				<WaveUpHero height='213' width='100%' fill='white' />
			</BgImage>
		</>
	);
};
