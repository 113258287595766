import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
	heroImgBg: {
		position: 'absolute',
		right: '-10%',
		top: '-5%',
		zIndex: 9999,
		maxWidth: '600px',
		[theme.breakpoints.down('md')]: {
			right: '-17%',
			bottom: '-60%',
			maxWidth: '550px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},

	heroImgBgProductivity: {
		position: 'absolute',
		right: '-3%',
		top: '-5%',
		zIndex: 9999,
		maxWidth: '520px',

		[theme.breakpoints.down('md')]: {
			top: '-18%',
			right: '-5%',
			maxWidth: '430px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},
	heroImgBgGrowth: {
		position: 'absolute',
		right: '-3%',
		top: '-5%',
		zIndex: 9999,
		maxWidth: '520px',

		[theme.breakpoints.down('md')]: {
			top: '-18%',
			right: '-5%',
			maxWidth: '430px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},

	heroImgBgScheduling: {
		position: 'absolute',
		right: '-11%',
		top: '-20%',
		zIndex: 9999,
		maxWidth: '630px',
		[theme.breakpoints.down('md')]: {
			right: '-20%',
			maxWidth: '550px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},

	heroImgBgCRM: {
		position: 'absolute',
		right: '-13%',
		top: '-20%',
		zIndex: 9999,
		maxWidth: '650px',
		[theme.breakpoints.down('md')]: {
			top: '-15%',
			right: '-25%',
			maxWidth: '630px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},
	heroImgBgMarketing: {
		position: 'absolute',
		right: '-11%',
		top: '-20%',
		zIndex: 9999,
		maxWidth: '630px',
		[theme.breakpoints.down('md')]: {
			right: '-20%',
			bottom: '-60%',
			maxWidth: '550px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},
	heroImgBgSupport: {
		position: 'absolute',
		right: '-3%',
		top: '-30%',
		zIndex: 9999,
		maxWidth: '520px',

		[theme.breakpoints.down('md')]: {
			top: '-23%',
			right: '-5%',
			maxWidth: '430px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},
	heroImgBgWhyServman: {
		position: 'absolute',
		right: '-5%',
		top: '-21%',
		zIndex: 9999,
		maxWidth: '525px',
		[theme.breakpoints.down('md')]: {
			top: '-56%',
      right: '-3%',
			maxWidth: '470px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '340px',
			maxWidth: '100%',
		},
	},
}));

export const ImgRight = ({ heroImage, id }) => {
	const classes = useStyles();
	const md = useMediaQuery('(max-width: 960px)');
	return (
		<Grid
			container
			item
			direction='column'
			// justifyContent='flex-start'
			alignItems={md ? 'center' : 'flex-start'}
			xs={12}
			md={5}>
			<GatsbyImage
				image={heroImage}
				alt='right image graphic'
				className={
					id === 'b720ff6c-323c-45e9-9d28-76e9904a29f5'
						? classes.heroImgBgSupport
						: // : id === "" ? classes.heroImgBgOverview
						id === '7426b498-801d-4c25-ab59-6aa0775806ba'
						? classes.heroImgBgProductivity
						: id === '78a3d60a-aacc-44a3-b76e-064cfd0ed9ee'
						? classes.heroImgBgGrowth
						: id.includes([
								'c4f9a546-7741-482d-b5e5-1aa284a8b25d',
								'21c45604-14e0-4c50-9370-a621c7e1726e',
								'1a6d9aba-0cb3-45fc-85e6-3cc8d9f2f38b',
								'd7fd28e1-65f0-4194-a269-e60b95ae7fe7',
								'3e2a9c73-b731-4e16-8a57-2461dd17699c',
								'c2a096e9-cf83-4625-89f7-88b3f7ddd8b1',
								'c4beb424-0bc6-47ea-9e15-6845125d866e',
								'e9df8d82-f7b8-4613-bd55-b7e70237645d',
								'ab8019df-8e8c-4806-81ae-85a83f4f82d3',
								'b21071eb-bc45-41ea-a10b-192431ce8eaa',
						  ])
						? classes.heroImgBgScheduling
						: id === 'f9a07114-dbba-451b-ac70-9776ce844729'
						? classes.heroImgBgCRM
						: id === '6ff01c2f-1718-47e1-8c9a-4926be069079'
						? classes.heroImgBgMarketing
						: id === '5b22fa47-72df-4b94-a1a8-31514063b59f'
						? classes.heroImgBgWhyServman
						: classes.heroImgBg
				}
			/>
		</Grid>
	);
};
