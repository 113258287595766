import React from 'react';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	intro: {
		textAlign: 'center',
		marginTop: '8rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '4rem',
			textAlign: 'left',
		},
	},
	firstLine: {
		color: theme.workwaveBlue,
		fontSize: '2.25rem',
		fontWeight: 700,
		[theme.breakpoints.down('md')]: {
			fontSize: '3rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.8rem',
		},
	},
	secondLine: {
		fontSize: '2.5rem',
		fontWeight: 700,
		color: '#2a7abc',
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '3.25rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2rem',
		},
	},
	paragraph: {
		lineHeight: '32px',
		marginTop: '1rem',
		fontFamily: ['Roboto', 'sans-serif'].join(),
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.25rem',
		},
	},
}));

export const Intro = ({ intro }) => {
	const classes = useStyles();
	return (
		<Grid
			container
			direction='row'
			justifyContent='center'
			alignItems='center'
			className={classes.intro}>
			<div style={{ zIndex: 1000 }}>
				<PortableText
					content={intro}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								color='primary'
								className={classes.firstLine}
								style={{ lineHeight: '1.0' }}>
								{children}
							</Typography>
						),
						strong: ({ children }) => (
							<span className={classes.secondLine}>{children}</span>
						),
						normal: ({ children }) => (
							<Typography variant='body1' className={classes.paragraph}>
								{children}
							</Typography>
						),
					}}
				/>
			</div>
		</Grid>
	);
};
