import React, { useState, useEffect } from 'react';

import { Container, Grid, makeStyles } from '@material-ui/core';

import { Intro } from './Intro';
import { CenterFeatures } from './CenterFeatures';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';

const useStyles = makeStyles((theme) => ({
	centerFeatures: {
		background: '#f5f9ff',
		padding: '14rem 0 16rem',
		[theme.breakpoints.down('sm')]: {
			padding: '10rem 0',
		},
	},
  introCont: {
    marginTop: '-8rem',
    marginBottom: '-3rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '-3rem',
      marginBottom: '-1rem',
		},
  },
}));

export const WhyServmanBody = ({ whyServman, id }) => {
	const [selected, setSelected] = useState(null);
	const { _rawIntro, centerSection } = whyServman;

	useEffect(() => {
		setSelected(centerSection.content[0]);
	}, []);

	const classes = useStyles();

	return (
		<>
			<Container className={classes.introCont}>
				<Intro intro={_rawIntro} />
			</Container>
			<WaveDownSVG fill='#FFF' shadow />
			<div className={classes.centerFeatures}>
				<CenterFeatures
					centerSection={centerSection}
					setSelected={setSelected}
					selected={selected}
				/>
			</div>
			<WaveUpSVG fill='#FFF' shadow />
		</>
	);
};
