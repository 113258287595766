import React from 'react';

import {
	Container,
	Typography,
	Grid,
	useMediaQuery,
	makeStyles,
} from '@material-ui/core';

import { MobileFeatures } from './Mobile/MobileFeatures';
import { DesktopFeatures } from './DesktopFeatures';

const useStyles = makeStyles((theme) => ({
	header: {
		textAlign: 'center',
		marginBottom: '2rem',
		fontSize: '2.25rem',
		[theme.breakpoints.down('sm')]: {
			margin: '0',
		},
	},
}));

export const CenterFeatures = ({ centerSection, setSelected, selected }) => {
	const { header, content } = centerSection;
	const md = useMediaQuery('(max-width: 960px)');
	const classes = useStyles();
	return (
		<Container>
			<Typography variant='h2' color='primary' className={classes.header}>
				{header}
			</Typography>
			{md ? (
				<MobileFeatures featureSection={content} />
			) : !!selected ? (
				<DesktopFeatures
					content={content}
					setSelected={setSelected}
					selected={selected}
				/>
			) : null}
		</Container>
	);
};
