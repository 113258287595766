import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { Grid, Button } from '@material-ui/core';

import FormModalContext from '../../context/FormModalContext';

export const HeroCtaButton = ({ hero }) => {
	const { ctaButtonText, ctaButtonLink, internalLink } = hero;
	const { setFormModalOpen } = useContext(FormModalContext);
	return (
		<Grid
			container
			justifyContent='center'
			alignItems='center'
			style={{ marginTop: '2rem' }}>
			{!!ctaButtonLink ? (
				internalLink ? (
					<Link to={`/${ctaButtonLink}`} style={{ textDecoration: 'none' }}>
						<Button color='secondary' size='large' variant='contained'>
							{ctaButtonText}
						</Button>
					</Link>
				) : (
					<a
						href={ctaButtonLink}
						target='_blank' aria-label="Opens in a new tab"
						style={{ textDecoration: 'none' }}>
						<Button color='secondary' size='large' variant='contained'>
							{ctaButtonText}
						</Button>
					</a>
				)
			) : (
				<Button
					color='secondary'
					size='large'
					variant='contained'
					onClick={setFormModalOpen}>
					{ctaButtonText}
				</Button>
			)}
		</Grid>
	);
};
